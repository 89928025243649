<template>
  <div class="content_box_list pos-r" @mouseenter="mouseenters" @mouseleave="mouseleaves">
    <div v-if="shadow.shadowStatus" class="bg_shadow dis-flex3 dis-flex4">
      <div>
        <img class="bg_shadow_img" :src="listinfoindex.urls_qr" alt="">
        <div class="center color-fff fz14">支付宝扫码查看案例</div>
      </div>
      
    </div>
    <div class="dis-flex2 box_list_info">
      <div class="list_info_left flex1">
        <div class="dis-flex3">
          <img class="info_left_img" :src="listinfoindex.urls_logo" alt="">
          <div class="info_left_band fz24 fw600 color333">{{listinfoindex.title}}</div>
          <div class="info_left_jingying color999"></div>
        </div>
        <div class="dis-flex2 info_flow">
          <div>
            <div class="color333 fz24 fw600 center">{{listinfoindex.user_num}}+</div>
            <div class="color999 fz16 center">累计用户</div>
          </div>
          <div>
            <div class="color333 fz24 fw600 center">{{listinfoindex.sales_num}}+</div>
            <div class="color999 fz16 center">月均GMV</div>
          </div>
        </div>
        <div class="color666 fz14">{{listinfoindex.dec}}</div>
      </div>
      <div class="list_info_right">
        <img class="info_right_img" :src="listinfoindex.urls_bg" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, } from "vue"
export default {
  name:'mouseenter',
  props:{
    listinfoindex:{
      type:Object,
    }
  },
  setup(){
    const shadow=reactive({shadowStatus:false})
    const mouseenters=()=>{
      shadow.shadowStatus=true
    }
    const mouseleaves=()=>{
      shadow.shadowStatus=false
    }
    return {mouseenters,mouseleaves,shadow,}
  }
}
</script>

<style lang="scss" scoped>
 .content_box_list{
    width:586px;
    // height:354px;
    flex-shrink:0;
    margin-bottom:28px;
    .bg_shadow{
      border-radius: 16px;
      background:rgba(9, 19, 63, 0.5);
      position:absolute;
      top:0;
      right:0;
      left:0;
      bottom:0;
      .bg_shadow_img{
        width:128px;
        height:128px;
        margin-bottom:6px;
      }
    }
    .box_list_info{
      padding:40px;
      box-shadow: 0px 0px 10px 1px #DDD;
      border-radius: 16px;
      .list_info_left{
        margin-right:40px;
        .info_left_img{
          width:54px;
          height:54px;
          margin-right:20px;
          border-radius: 50%;
        }
        .info_left_band{
          margin-right:16px;
        }
        .info_flow{
          padding:30px 0;
        }
      }
      .list_info_right{
        width:186px;
        height:400px;
        .info_right_img{
          width:186px;
          height:400px;
          border-radius: 16px;
        }
      }
    }
  }

</style>