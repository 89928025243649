<template>
  <div id="nav" class="pos-r">
     <!-- <Navigation :tapInfos="tapInfos" /> -->
     <!-- <Swipers></Swipers> -->
     <Bgimgs urls="https://fmcg.oss-cn-hangzhou.aliyuncs.com/pc/bg_banner_a1.png"></Bgimgs>
  </div>
  <List></List>
  <!-- <Search></Search>
  <Footers></Footers> -->
  <!-- <Bottom></Bottom> -->
</template>
<script>
// @ is an alias to /src
// import Navigation from '@/components/Navigation'
// import Swipers from '@/components/Swipers'
import Bgimgs from '@/components/Bgimgs'
import List from '@/components/List'
// import Search from '@/components/Search'
// import Footers from '@/components/Footers'
// import Bottom from '@/components/Bottom'

export default {
  name: 'Home',
  components: {
    // Navigation,
    // Swipers,
    Bgimgs,
    List,
    // Search,
    // Footers,
    // Bottom,
  },
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  color: #2c3e50;
}

#nav {
  background:#fff ;
  text-align: center;
  .bg_banner{
    height:600px;
  }
}

</style>
