<template>
  <div class="content dis-flex4">
    <div class="content_box">
      <div class="dis-flex2 flexWrap">
        <template v-for="(item,index) in listinfo" :key="index">
          <Listinfo :listinfoindex="item"></Listinfo>
          <!-- <Listinfo :listinfoindex="item"></Listinfo>
          <Listinfo :listinfoindex="item"></Listinfo>
          <Listinfo :listinfoindex="item"></Listinfo> -->
        </template>
      </div>
      <div class="dis-flex2">
        
      </div>
    </div>
  </div>
</template>

<script>
import Listinfo from '@/components/Listinfo'
import { reactive,toRefs } from 'vue'
export default {
  components:{
    Listinfo
  },
  setup(){
    const state=reactive({
      listinfo:[
        {urls_bg:'https://fmcg.oss-cn-hangzhou.aliyuncs.com/pc/junyao_bg.png',urls_qr:require('@/assets/img/junyao_qr.jpeg'),urls_logo:require('@/assets/img/junyao_logo.jpg'),title:'均瑶味动力',user_num:'200W',sales_num:'80W',dec:'均瑶味动力小程序，通过瓶身扫码互动导流，实现海量营销曝光，降低营销成本，留存精准客户，完成销售转化。'},
        {urls_bg:'https://fmcg.oss-cn-hangzhou.aliyuncs.com/pc/qiaqia_bg.png',urls_qr:require('@/assets/img/qiaqia_qr.jpg'),urls_logo:require('@/assets/img/qiaqia_logo.png'),title:'洽洽',user_num:'274W',sales_num:'100W',dec:'洽洽小程序，作为品牌全新销售渠道，利用公域流量承接、私域活动开展等方式，实现品牌销量显著提升。'},
         {urls_bg:'https://fmcg.oss-cn-hangzhou.aliyuncs.com/pc/whh_bg.png',urls_qr:require('@/assets/img/whh_qr.jpg'),urls_logo:require('@/assets/img/whh_logo.png'),title:'娃哈哈',user_num:'800W',sales_num:'110W',dec:'娃哈哈到家小程序，调动线下桶装水配送站资源，实现门店营销数字化与门店管理数字化，提高业务效率，实现用户拉新。'},
         {urls_bg:'https://fmcg.oss-cn-hangzhou.aliyuncs.com/pc/xxw_bg.png',urls_qr:require('@/assets/img/xxw_qr.jpg'),urls_logo:require('@/assets/img/xxw_logo.png'),title:'新希望',user_num:'215W',sales_num:'200W',dec:'新希望小程序，以会员运营为中心，以会员卡、社群等工具为抓手，实现用户沉淀与触达，提高复访与复购。'},
         {urls_bg:'https://fmcg.oss-cn-hangzhou.aliyuncs.com/pc/icon_jingyuetang.jpg',urls_qr:require('@/assets/img/jingyuetang_qr.png'),urls_logo:require('@/assets/img/jingyuetang_logo.png'),title:'景岳堂',user_num:'8w',sales_num:'30w',dec:'景岳堂小程序，联合线下200家门店，以养生五谷与花果茶为主打产品，通过支付宝实现营销数字化，在养生经济里，找到新的增长点。'},
         {urls_bg:'https://fmcg.oss-cn-hangzhou.aliyuncs.com/pc/icon_liangbu.jpg',urls_qr:require('@/assets/img/liangbu_qr.png'),urls_logo:require('@/assets/img/liangbu_logo.jpg'),title:'良布',user_num:'5w',sales_num:'1w',dec:'良布小程序，作为品牌全新销售渠道，通过多系列产品，树立适用于年轻人的中高端新零售品牌形象。'},
      ]
    })
    return {...toRefs(state)}
  }
}
</script>

<style lang="scss" scoped>
  .content{
    .content_box{
      width:1200px;
      padding:80px 0;
      padding-bottom:52px;
    }
  }
</style>